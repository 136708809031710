<template>
  <div>
    <h3>{{company.name}}</h3>
    <div class="d-flex">
    <label>Einddatum:</label><input type="date" v-model="endDate" />
    <button class="no-print" @click="setOutstandingDebtors">Zoeken</button
    ><button class="no-print" @click="resetData">Reset</button>
    <button class="no-print" @click="print">Afdrukken</button>
    <search-client
                class="search-client"
                :searchString="searchString"
                @setSearchString="setSearchString"
                @get-results="setClientNumber"
                @clear-input="clearClientNumber"
              ></search-client>
              </div>
    <b>Totaal: {{ toCurrency(outstandingArray.reduce((acc,cur)=>acc+cur.invoices.reduce((acc2,cur2)=>acc2+cur2.debit,0),0))}}</b>
    <div class="d-flex justify-center block" v-for="object of outstandingArray" :key="object.clientNumber">
  <table>
    <tr>
      <th>Klantnr:{{object._id}}</th>
      <th>{{object.name}}</th>
      <td>{{object.client.address}}</td>
      <td>{{object.client.postalcode}}</td>
      <td>{{object.client.city}}</td>
      <td>{{object.client.phone}} | {{object.client.mobile}}</td>
    </tr>
    <tr>
      <th>Factuur</th>
      <th>Datum</th>
      <th>Vervaldatum</th>
      <th>Bedrag</th>
      <th>Betaald</th>
      <th>Openstaand</th>
    </tr>
    <tr  v-for="invoice of object.invoices" :key="invoice._id">
      <td>{{invoice.invoiceNumber}}</td>
      <td>{{new Date(invoice.date).toLocaleDateString("NL")}}</td>
      <td>{{new Date(invoice.dueDate).toLocaleDateString("NL")}}</td>
      <td>{{ toCurrency(invoice.grossTotal)}}</td>
      <td>{{ toCurrency(invoice.credit)}}</td>
      <td>{{ toCurrency(invoice.debit)}}</td>      
    </tr>
    <tr>
      <td colspan="4"></td>
      <td><b>Totaal:</b></td>
      <td><b>{{ toCurrency(object.invoices.reduce((acc,cur)=>acc+cur.debit,0))}}</b></td>
    </tr>
  </table>
  <hr>
  </div>
  </div>
</template>

<script>
import { fetchGET,toCurrency } from "../js/functions";
import searchClient from '@/components/searchClient.vue';
export default {
  name: "outstandingDebtors",
  data() {
    return {
      endDate: new Date(),
      outstandingArray: [],
      response: "",
      searchString: "",
      clientNumber: 0
    }
  },
  methods: {
    async setOutstandingDebtors() {
      this.outstandingArray = await fetchGET("setOutstandingDebtors",{endDate: this.endDate,clientNumber: this.clientNumber})
    },
    resetData() {
      this.endDate = new Date
      this.outstandingArray = []
    },
    print(){
      window.print()
    },
    toCurrency(val){
      return toCurrency(val)
    },
    setSearchString(val) {
      this.searchString = val
    },
    async setClientNumber(val) {
      this.clientNumber = val.clientNumber
    },
    clearClientNumber() {
      this.searchString = ""
      this.clientNumber = ""
    }
  },
  computed: {
    invoices() {
      return this.$store.state.invoices
    },
    company(){
      return this.$store.state.settings.company
    }
  },
  components: {
    searchClient,
  },
}
</script>

<style scoped>
input {
  background: white;
}
button {
  width: 100px;
  height: 50px;
  background: blue;
  color: white;
}
.block {
  background:  white;
}
th,td{
  text-align: left;
  width:200px;
}

</style>